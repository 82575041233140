import dynamic from "next/dynamic";

import NewsletterData from "../../data/elements/newsletter.json";
import { subscribeNewsletter } from "@/api/sub";
import { useRef } from "react";
import { toast } from "react-toastify";

const Odometer = dynamic(() => import("react-odometerjs"), {
  ssr: false,
  loading: () => 0,
});

const NewsletterTwo = () => {
  const emailRef = useRef(null)

  const handleSubNewsletter = async () => {
    let email = emailRef.current?.value
    if (email == null || email == "") {
      toast.error("Email address needed!")
      return
    }
    try {
      await subscribeNewsletter({email})
      toast.success("Subscribe email success")
    } catch(e) {
      toast.error(e.response.data.message)
    }
  }

  return (
    <div className="container">
      <div className="row row--15 align-items-center">
        <div className="col-lg-12">
          {NewsletterData &&
            NewsletterData.newsletterTwo.map((data, index) => (
              <div className="inner text-center" key={index}>
                <div className="section-title text-center">
                  <span className="subtitle bg-white-opacity">
                    {data.subTitle}
                  </span>
                  <h2 className="title color-white">
                    <strong>{data.strong}</strong> {data.title}
                  </h2>
                  <p className="description color-white mt--20">{data.desc}</p>
                </div>
                <div className="newsletter-form-1 mt--40">
                  <input ref={emailRef} type="email" placeholder="Enter Your E-Email" />
                  <button
                    className="rbt-btn btn-md btn-gradient hover-icon-reverse"
                    onClick={handleSubNewsletter}
                  >
                    <span className="icon-reverse-wrapper">
                      <span className="btn-text">Subscribe</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </span>
                  </button>
                </div>
                <span className="note-text color-white mt--20">
                  {data.text}
                </span>

                
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default NewsletterTwo;
