import Image from "next/image";
import Link from "next/link";

import FeaturesDetails from "../../data/features.json";

const Features = () => {

  return (
    <>
      {FeaturesDetails &&
        FeaturesDetails.features.slice(0, 4).map((item, innerIndex) => {
          return (
            <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={innerIndex}>
              <Link
                className="rbt-cat-box rbt-cat-box-1 text-center"
                href='#'
              >
                <div className="inner">
                  <div className="icons">
                    <Image
                      src={item.img}
                      width={200}
                      height={200}
                      priority
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h5 className="title">{item.feature}</h5>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
    </>
  );
};

export default Features;
