import { FiCopy } from "react-icons/fi";
import { formatAddress } from "@/utils/formatter";
import copy from "@/utils/copyToClipboard";

export default function WalletAddress({address, cut}) {
  
  return (
    <>
      <div className="flex items-center pt-2">
        <span className="pr-2 text-2xl">{formatAddress(address, cut)}</span>
        <span className="hover:cursor-pointer" onClick={()=>copy({text: address})}><FiCopy size={16} /></span>
      </div>
    </>
  )
}