import { toast } from 'react-toastify';

/**
 * @desc 复制功能
 * @params text 复制文本内容
 * @params success 回调
 * @params fail 回调
 */
async function copy({ text, success, fail }) {
  try {
    navigator.clipboard.writeText(text)
    success ? success() : toast.success("success")
  } catch (e) {
    fail ? fail() : toast.error("copy error")
  }
}

export default copy
