import axios from 'axios';

export async function subscribeNewsletter({email}) {
  const http = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });
  
  const beforeRequest = config => {
    // 设置 token
    config.headers['Authorization'] = "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNWU4NGM3M2I2M2E0MTBiYWU4ODQyMWU3MmYyOWYyN2U5OWZkY2M3ODg1YjUxZDhjOWExZmYwM2VkNzUwZDJhODk3NWUyY2M3OWExMTAyZmUiLCJpYXQiOiIxNzExMDIxNDkxLjE2NjI2MCIsIm5iZiI6IjE3MTEwMjE0OTEuMTY2MjY0IiwiZXhwIjoiNDg2NDYyMTQ5MS4xNjQwNzIiLCJzdWIiOiI4NjIzMDQiLCJzY29wZXMiOltdfQ.D0_23olO9NEFs_DLuQc2wfw4g6hcSu2KIV9iKuSDhw2NXMv0ENqDHEZeq_tBED5M5v2O8AeZxdYa4NcMvwhmJg"
    return config
  }
  
  http.interceptors.request.use(beforeRequest,
    (err) => {
      throw new Error(err);
     // some action 
    }
  );
  
  return await http.post(
    "https://api.sender.net/v2/subscribers", 
    {
      email,
      groups: [
        "e3YYoA"
      ],
    }
  )
}