import Image from "next/image";
import Link from "next/link";
import { useRef } from "react";
import QRCode from "react-qr-code";
import { EthereumBadge, BitcoinBadge } from "cryptocons"
import WalletAddress from '@/components/walletAddress'
import img from "../../public/images/banner/banner_trade.png";
import shape1 from "../../public/images/shape/shape-01.png";
import shape2 from "../../public/images/shape/shape-02.png";

const MainDemoBanner = () => {
  const donationModal = useRef(null)

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 pb--120 pt--70 space-responsive-xxl">
            <div className="content">
              <div className="inner">
                <div className="rbt-new-badge rbt-new-badge-one">
                  <span className="rbt-new-badge-icon">🏆</span>&nbsp;
                  Crypto Investment Management
                </div>

                <h1 className="title">
                  Create portfolios and manage
                  <br/>
                  <span className="color-title">Crypto Investment</span>
                  <br/>
                  with your team
                </h1>
                <p className="description">
                  This is a free tool to manage your crypto investment, including assets management, investment dicisions, investor management, etc 
                </p>
                <div className="slider-btn flex flex-col md:flex-row items-center gap-4">
                  <Link
                    className="rbt-btn btn-gradient hover-icon-reverse"
                    href={process.env.NEXT_PUBLIC_PALETTE_APP_URL}
                    target="_blank"
                  >
                    <span className="icon-reverse-wrapper">
                      <span className="btn-text">Launch App</span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                      <span className="btn-icon">
                        <i className="feather-arrow-right"></i>
                      </span>
                    </span>
                  </Link>

                  <div className="hover:underline hover:cursor-pointer" onClick={(e) => {e.preventDefault();donationModal.current?.showModal()}}>Thanks for your donation!</div>
                </div>
              </div>
              <div className="shape-wrapper" id="scene">
                <Image src={img} width={1200} height={1400} alt="Hero Image" />
                <div className="hero-bg-shape-1 layer" data-depth="0.4">
                  <Image
                    src={shape1}
                    width={428}
                    height={377}
                    alt="Hero Image Background Shape"
                  />
                </div>
                <div className="hero-bg-shape-2 layer" data-depth="0.4">
                  <Image
                    src={shape2}
                    width={372}
                    height={396}
                    alt="Hero Image Background Shape"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <dialog id="donationModal" ref={donationModal} className="!modal !modal-bottom sm:!modal-middle">
        <div className="!modal-box !max-w-3xl !w-full">
          <h3 className="font-bold !text-3xl">Thanks for your donation!</h3>
          <div className="flex justify-center gap-16 pt-8 pb-4">
            <div className="flex flex-col items-center text-base">
              <div className="relative">
                <QRCode value={process.env.NEXT_PUBLIC_ETH_DONATION_WALLET} size={168} level="H" title="BTC Donation Address"/>
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 origin-center border-2 rounded border-white	bg-white" >
                  <EthereumBadge size={36} />
                </div>
              </div>
              <WalletAddress address={process.env.NEXT_PUBLIC_ETH_DONATION_WALLET} cut={[6,4]}/>
              <span className="pt-4 text-2xl">Donate ETH</span>
            </div>
            <div className="flex flex-col items-center text-base">
              <div className="relative">
                <QRCode value={process.env.NEXT_PUBLIC_BTC_DONATION_WALLET} size={168} level="H" title="BTC Donation Address"/>
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 origin-center border-2 rounded border-white	bg-white" >
                  <BitcoinBadge size={36} />
                </div>
              </div>
              <WalletAddress address={process.env.NEXT_PUBLIC_BTC_DONATION_WALLET} cut={[6,6]}/>
              <span className="pt-4 text-2xl">Donate BTC</span>
            </div>
          </div>
        </div>
        <form method="dialog" className="!modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
};

export default MainDemoBanner;
